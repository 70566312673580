const productPage = document.querySelector(".single-products");

function formatTime(time, lang) {
	if (lang == "nb-NO") {
		return time.toLocaleTimeString("no-NB", {
			hour: "2-digit",
			minute: "2-digit",
		});
	} else {
		return time.toLocaleTimeString("en-US", {
			timeStyle: "short",
		});
	}
}

function formatDate(date, lang) {
	if (lang == "nb-NO") {
		return date.toLocaleDateString("no-NB", {
			day: "numeric",
			month: "long",
		});
	} else {
		return date.toLocaleDateString("en-US", {
			day: "numeric",
			month: "long",
		});
	}
}

// Get next departure from iTicket
if (productPage) {
	const productCode = productPage
		.querySelector(".product-details")
		.getAttribute("data-iticket-productcode");
	const departureTextField = productPage.querySelector(".next-departure");
	const loader = productPage.querySelector(".loader");
	const todayDateString = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

	// Some products have daily departures and a custom text, and dont need to show next departure. Ex cbis code 2135799 and 2608117. These products does not have next-departure field.
	if (departureTextField) {
		jQuery.ajax({
			type: "POST",
			url: rodne_scripts.ajax_url,
			data: {
				action: "get_product_departures",
				product_code: productCode,
			},
			success: function (output) {
				const departures = output["departures"];

				let nextAvailableTour = null;

				if (departures.length > 0) {
					let i = 0;
					while (nextAvailableTour === null) {
						const departure = departures[i];

						if (departure.availableCapacity > 0) {
							nextAvailableTour = departure;
						}

						i++;
					}
				}

				if (nextAvailableTour) {
					const lang = document.documentElement.lang;
					let nextDepartureDate;
					let nextDepartureTime;
					const departureDatetime = nextAvailableTour["datetime"];

					if (lang == "nb-NO") {
						// Format the dates
						nextDepartureDate = new Date(
							departureDatetime
						).toLocaleDateString("nb-NO", {
							day: "numeric",
							month: "long",
						});

						nextDepartureTime = new Date(
							departureDatetime
						).toLocaleTimeString("nb-NO", {
							hour: "2-digit",
							minute: "2-digit",
						});
					} else {
						nextDepartureDate = new Date(
							departureDatetime
						).toLocaleDateString("en-US", {
							day: "numeric",
							month: "long",
						});

						nextDepartureTime = new Date(
							departureDatetime
						).toLocaleTimeString("en-US", {
							timeStyle: "short",
						});
					}

					// print out next departure
					departureTextField.innerHTML =
						nextDepartureTime + ", " + nextDepartureDate;
				} else {
					const noFutureDepartures = productPage.querySelector(
						".no-future-departures"
					);
					const departureTextField = productPage.querySelector(
						".next-departure-pre-text"
					);
					noFutureDepartures.classList.remove("hidden");
					departureTextField.classList.add("hidden");
				}

				// Hide loader
				loader.classList.add("hidden");
			},
		});
	}
}

// Connect product details links to accordion
if (productPage) {
	const productDetails = document.querySelector(".product-details");
	const allLinks = productDetails.querySelectorAll("a");
	const accordions = document.querySelectorAll(".accordion .element-wrapper");

	// put accordions in object where data-id is key
	const accordionObj = {};
	accordions.forEach((accordion) => {
		const id = accordion.getAttribute("data-id");
		accordionObj[id] = accordion;
	});

	//get the url and split out #

	allLinks.forEach((link) => {
		link.addEventListener("click", function (e) {
			// Get href attribute without #
			const href = link.getAttribute("href").split("#")[1];

			// Check if href is in accordionObj
			if (accordionObj[href]) {
				e.preventDefault();
				// Open accoridon with class
				accordionObj[href].classList.add("show-dropdown");

				// Scroll down to accordion
				const offset = accordionObj[href].offsetTop - 100;
				window.scrollTo({
					top: offset,
					behavior: "smooth",
				});
			}
		});
	});
}
